export function usePage (title: Ref<string>) {
  /**
   * head tag
   * ==================================================================
   */
  useHead({
    title: `${title.value} | Revolution Prep`
  })

  /**
   * nuxt app
   * ==================================================================
   */
  const { $actor } = useNuxtApp()

  // computed
  const actor = computed(() => {
    return $actor.core.actor.value
  })

  return {
    actor
  }
}
